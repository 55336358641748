import { post, url } from "@rigly/core";
import { getToken } from "../utils/token";
import { getDefaultHeaders } from "../utils/headers";
import toast from "react-hot-toast";
import { BASE_API_URL } from "../constants";

export async function getTotalHashrate(teamId: number) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    const res = await post(
      url(BASE_API_URL, `/api/sellers/statistics/total-hashrate`),
      {
        headers: getDefaultHeaders(token),
      },
      {
        team: teamId,
      }
    );

    return res;
  } catch (ex: any) {
    console.error("Error in getTotalHashrate:", ex);
    throw ex; // Re-throw the error to be caught in the calling function
  }
}

export async function getMonthlyRevenue(teamId: number) {
  try {
    const token = getToken();
    if (!token) {
      throw new Error("Invalid token");
    }

    const res = await post(
      url(BASE_API_URL, `/api/sellers/statistics/monthly-revenue`),
      {
        headers: getDefaultHeaders(token),
      },
      {
        team: teamId,
      }
    );

    return res;
  } catch (ex: any) {
    toast.error(ex.message, { position: "bottom-right" });
  }
}
