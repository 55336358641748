import { Button, Breadcrumbs, Card, List, Loader } from "@rigly/core";

import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import { useEffect, useState } from "react";
import { getPayouts, getPayoutsChart, getPayoutsCSV, requestPayout } from "../../api/payouts";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import Plot from "../../components/Plot";
import clsx from "clsx";
import { PayoutResponse } from "../../types";
import View from "../../components/View";
import toast from "react-hot-toast";
import { useAuthContext } from "../../context/AuthContext";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../constants";
import Toolbar from "../../components/Toolbar";
import Pagination from "../../components/Pagination";
import exportFromJSON from "export-from-json";
import { listUTCDateFormat } from "../../lib/utc-date-fns";
import { UTCDate } from "@date-fns/utc";

function formatTxId(txid: string) {
  return `${txid.slice(0, 4)}...${txid.slice(-4)}`;
}

function RequestPayoutButton({ payoutRes }: { payoutRes: PayoutResponse }) {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRequestPayout = async (e: any) => {
    e.stopPropagation();

    try {
      setLoading(true);
      const res = await requestPayout(payoutRes.epoch);
      console.log(res);
      setIsProcessing(true);
    } catch (ex: any) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  };

  const { payout } = payoutRes;
  if (payout?.status === "requested" || isProcessing) {
    return (
      <span className="text-zinc-600 dark:text-white text-xs font-semibold block text-center">
        Payout processing
      </span>
    );
  }

  if (payout?.status === "granted") {
    return (
      <span className="text-green-600 dark:text-green-500 text-xs font-semibold block text-center">
        Payout complete
      </span>
    );
  }

  if (payout?.status === "denied") {
    return (
      <span className="text-red-600 dark:text-red-500 text-xs font-semibold block text-center">
        Payout denied
      </span>
    );
  }

  return (
    <Button
      className="w-full h-[32px] justify-center bg-blue-500 dark:bg-blue-500 border-blue-400 dark:border-blue-400 hover:bg-blue-400 dark:hover:bg-blue-400 hover:border-300 dark:hover:border-blue-300"
      onClick={handleRequestPayout}
    >
      {loading && <Loader className="w-6 h-6 text-white dark:text-white" />}
      {!loading && (
        <span
          className={clsx(
            "text-sm text-center font-sans font-semibold text-white dark:text-white"
          )}
        >
          Request Payout
        </span>
      )}
    </Button>
  );
}

export default function Payouts() {
  const { team } = useAuthContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const [payouts, setPayouts] = useState<PayoutResponse[]>([]);
  const [chartData, setChartData] = useState<any>(undefined);
  const [selectedEpoch, setSelectedEpoch] = useState<number | undefined>(
    undefined
  );
  const [totalActive, setTotalActive] = useState<boolean>(true);
  const [averageActive, setAverageActive] = useState<boolean>(true);

  // pagination
  const [limit, setLimit] = useState<number>(8);
  const [activePage, setActivePage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  const hanldeExportCSV = () => {
    getPayoutsCSV().then((res) => {
      if (res && res.data) {
        const fileName = 'revenue'
        const exportType = exportFromJSON.types.csv
        exportFromJSON({ data: res.data, fileName, exportType })
      }
    })
  }

  const loadPayouts = async () => {
    try {
      setLoading(true);
      const res = await getPayouts(limit, activePage * limit, "all", team?.id, limit, activePage * limit);
      if (res && res.results) {
        setPayouts(res.results);
        setCount(res.count);
      }
    } catch (ex: any) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  };

  const loadChart = async () => {
    try {
      setLoadingChart(true);
      const res = await getPayoutsChart("all", team?.id);
      setChartData(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-right" });
    } finally {
      setLoadingChart(false);
    }
  };

  useEffect(() => {
    if (team) {
      loadPayouts();
      loadChart();
    }
  }, [team]);

  useEffect(() => {
    if (team) {
      loadPayouts();
    }
  }, [limit, activePage]);

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{APP_NAME} - Revenue</title>
      </Helmet>
      <Topbar />
      <div className="flex min-h-screen-excluding-topbar">
        <View className="bg-radial-gradient-dark dark:bg-radial-gradient-light">
          <Sidebar />
          <div className="p-1">
            <Card className="pt-3 p-4 gap-4 items-start h-full payouts-grid bg-radial-gradient-dark dark:bg-radial-gradient-light">
              <Breadcrumbs
                items={[{ label: "All", href: "/revenue" }]}
                onNavigate={(to: string) => navigate(to)}
              />
              <Toolbar className='!items-center !flex-row justify-between'>
                <Pagination
                  count={count}
                  limit={limit}
                  setLimit={setLimit}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
                <button
                  onClick={hanldeExportCSV}
                  disabled={loading || Boolean(!payouts?.length)}
                  type="button"
                  className="rounded-md bg-blue-500 p-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Download csv
                </button>
              </Toolbar>
              <List
                loading={loading}
                loadingMessage="Crunching data..."
                data={payouts}
                className="w-full h-full min-h-[40vh]"
                spanClass="text-white text-xs"
                onClick={(e: any, payout: PayoutResponse) => {
                  if (e.detail === 1) {
                    setSelectedEpoch(payout.epoch);
                  }
                  if (e.detail === 2) {
                    navigate(`/revenue/${payout.epoch}`);
                  }
                }}
                selected={(payout: PayoutResponse) =>
                  payout.epoch === selectedEpoch
                }
                schema={[
                  { label: "Epoch", key: "epoch", type: "number", align: "left" },
                  {
                    label: "Date",
                    key: (payout: PayoutResponse) => {
                      const formattedStartDate = payout.start_at
                        ? listUTCDateFormat(new UTCDate(payout.start_at))
                        : "Unknown";
                      const formattedEndDate = payout.end_at
                        ? listUTCDateFormat(new UTCDate(payout.end_at))
                        : "Unknown";

                      return (
                        <div className="flex items-center justify-start gap-1">
                          <span className="text-zinc-600 dark:text-white text-xs">
                            {formattedStartDate + " - " + formattedEndDate}
                          </span>
                        </div>
                      );
                    },
                    type: "custom",
                  },
                  {
                    label: "Total Hashrate [TH/s]",
                    type: "custom",
                    key: (payout: PayoutResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.average_hashrate_thsd.toFixed(2)}
                      </span>
                    ),
                  },
                  {
                    label: "Passthrough [TH/s]",
                    type: "custom",
                    key: (payout: PayoutResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.seller_hashrate_thsd.toFixed(2)}
                      </span>
                    ),
                  },
                  {
                    label: "Rigly Premium [TH/s]",
                    type: "custom",
                    key: (payout: PayoutResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.order_hashrate_thsd.toFixed(2)}
                      </span>
                    ),
                  },
                  {
                    label: "FPPS Hashprice",
                    type: "sats",
                    key: (payout: PayoutResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.hashprice_satsthsd.toFixed(1)}
                      </span>
                    ),
                  },
                  {
                    label: `Rigly Hashprice (+${team?.hash_price_premium}%)`,
                    type: "sats",
                    key: (payout: PayoutResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {(payout.hashprice_satsthsd * ((100.0 + team?.hash_price_premium) / 100.0)).toFixed(1)}
                      </span>
                    ),
                  },
                  {
                    label: "Balance",
                    type: "custom",
                    key: (payout: PayoutResponse) => {
                      return (
                        <span className="text-zinc-600 dark:text-white text-xs">
                          {(payout.payout_amount_sats / 10 ** 8).toFixed(8)} BTC
                        </span>
                      );
                    },
                  },
                  /*
                  {
                    label: "TX ID",
                    type: "custom",
                    key: (payoutRes: PayoutResponse) => {
                      if (!payoutRes.payout || !payoutRes.payout.txid) {
                        return (
                          <div className="w-full h-full flex justify-center items-center">
                            <span className="text-zinc-600 dark:text-white text-xs">
                              -
                            </span>
                          </div>
                        );
                      }
                      return (
                        <div className="w-full h-full flex justify-center items-center">
                          <button
                            onClick={(e: any) => {
                              e.stopPropagation();
                              if (!payoutRes.payout) {
                                return;
                              }
                              window.open(
                                `https://mempool.space/address/${payoutRes.payout.txid}`,
                                'target="_blank"'
                              );
                            }}
                            className="text-xs text-white underline"
                          >
                            {formatTxId(payoutRes.payout.txid)}
                          </button>
                        </div>
                      );
                    },
                  },
                  {
                    label: "Actions / Status",
                    type: "custom",
                    key: (payoutRes: PayoutResponse) => {
                      return <RequestPayoutButton payoutRes={payoutRes} />;
                    },
                  },
                  */
                ]}
              />
              <div className="w-full h-full rounded-lg bg-zinc-100 dark:bg-zinc-800 border border-zinc-300 dark:border-zinc-700 flex flex-col relative">
                <div className="w-full pl-3 pr-1 py-1 border-b border-zinc-300 dark:border-zinc-700 flex items-center justify-between">
                  <span className="text-xs text-zinc-600">
                    Showing -{" "}
                    <b>{selectedEpoch ? `Epoch ${selectedEpoch}` : "All"}</b>
                  </span>
                  {/* <div className="flex items-center justify-center gap-4 absolute w-full h-full pointer-events-none">
                    {[
                      {
                        label: "Total",
                        active: totalActive,
                        toggle: () => setTotalActive(!totalActive),
                      },
                      {
                        label: "Average",
                        active: averageActive,
                        toggle: () => setAverageActive(!averageActive),
                      },
                    ].map((item: any, i: number) => {
                      return (
                        <div
                          key={i}
                          className="flex justify-start gap-2 items-center pointer-events-auto cursor-pointer"
                          onClick={item.toggle}
                        >
                          <div
                            className={clsx("w-3 h-3 rounded-sm border", {
                              "border-white/50": item.active,
                              "border-white/20": !item.active,
                            })}
                            style={{
                              background: getColorByIndex(
                                i,
                                item.active ? 1 : 0.5
                              ),
                            }}
                          />
                          <span
                            className={clsx("text-xs font-bold", {
                              "text-zinc-500 dark:text-white": item.active,
                              "text-zinc-300 dark:text-white/50": !item.active,
                            })}
                          >
                            {item.label}
                          </span>
                        </div>
                      );
                    })}
                  </div> */}
                  {selectedEpoch && (
                    <Button
                      className="w-auto px-2 h-[32px] text-xs"
                      onClick={() => setSelectedEpoch(undefined)}
                    >
                      Show All
                    </Button>
                  )}
                  {!selectedEpoch && <div className="h-[32px]" />}
                </div>
                <Plot
                  className="w-full h-full"
                  data={chartData?.data.map((dataset: any) => {
                    if (
                      (!averageActive &&
                        dataset.meta.key === "average_hashrate") ||
                      (!totalActive && dataset.meta.key === "total_hashrate")
                    ) {
                      return {
                        ...dataset,
                        x: [],
                        y: [],
                      };
                    }

                    if (!selectedEpoch) {
                      return dataset;
                    }

                    const res = payouts.find(
                      (payout: PayoutResponse) => payout.epoch === selectedEpoch
                    );

                    if (!res || !res.start_at || !res.end_at) {
                      return dataset;
                    }

                    const startTime = new Date(res.start_at);
                    const endTime = new Date(res.end_at);

                    const dates: any = [];
                    const hashrates: any = [];
                    dataset.x.forEach((ts: string, i: number) => {
                      const timestamp = new Date(ts);
                      if (timestamp >= startTime && timestamp <= endTime) {
                        dates.push(ts);
                        hashrates.push(dataset.y[i]);
                      }
                    });

                    return {
                      ...dataset,
                      x: dates,
                      y: hashrates,
                    };
                  })}
                  loading={loadingChart}
                  loadingMessage="Crunching data..."
                  xaxis={chartData?.layout?.xaxis}
                  yaxis={chartData?.layout?.yaxis}
                  layout={chartData?.layout}
                />
              </div>
            </Card>
          </div>
          </View>
      </div>
    </div>
  );
}
