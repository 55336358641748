import { Breadcrumbs, Button, Card, List } from "@rigly/core";

import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import { useEffect, useState } from "react";
import { getPayouts, getPayoutsChart } from "../../api/payouts";
import { PayoutResponse, PayoutResolution, Worker } from "../../types";
import { useNavigate, useParams } from "react-router";
import Plot from "../../components/Plot";
import View from "../../components/View";
import toast from "react-hot-toast";
import { useAuthContext } from "../../context/AuthContext";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../constants";
import Toolbar from "../../components/Toolbar";
import Pagination from "../../components/Pagination";
import exportFromJSON from "export-from-json";
import { listUTCDateFormat } from "../../lib/utc-date-fns";
import { UTCDate } from "@date-fns/utc";

export default function Payouts() {
  const { team } = useAuthContext();
  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any>(undefined);
  const [payouts, setPayouts] = useState<PayoutResponse[]>([]);
  const [selectedWorker, setSelectedWorker] = useState<Worker | undefined>(
    undefined
  );

  // pagination
  const [limit, setLimit] = useState<number>(8);
  const [activePage, setActivePage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  const hanldeExportCSV = () => {
    const fileName = 'revenue-workers'
    const exportType = exportFromJSON.types.csv
    exportFromJSON({ data: payouts, fileName, exportType })
  }

  const loadPayouts = async (
    resolution: PayoutResolution,
    epoch?: number,
    date?: any
  ) => {
    try {
      setLoading(true);
      const res = await getPayouts(limit, activePage * limit, resolution, team.id, epoch, date);
      if (res && res.results) {
        setPayouts(res.results);
        setCount(res.count);
      }
    } catch (ex: any) {
      console.log(ex);
    } finally {
      setLoading(false);
    }
  };

  const loadChart = async (
    resolution: PayoutResolution,
    epoch?: number,
    date?: any
  ) => {
    try {
      setLoadingChart(true);
      const res = await getPayoutsChart(resolution, team.id, epoch, date);
      setChartData(res);
    } catch (ex: any) {
      toast.error(ex.message, { position: "bottom-right" });
    } finally {
      setLoadingChart(false);
    }
  };

  useEffect(() => {
    if (team) {
      const epoch = params.epoch ? Number(params.epoch) : undefined;
      const date = params.date ? params.date : undefined;

      if (epoch && date) {
        loadPayouts("date", epoch, date);
        loadChart("date", epoch, date);
      } 
    }
  }, [params, team]);

  useEffect(() => {
    if (team) {
      const epoch = params.epoch ? Number(params.epoch) : undefined;
      const date = params.date ? params.date : undefined;

      if (epoch && date) {
        loadPayouts("date", epoch, date);
      } 
    }
  }, [limit, activePage]);

  return (
    <div className="flex flex-col">
      <Helmet>
        <title>{APP_NAME} - Revenue</title>
      </Helmet>
      <Topbar />
      <div className="flex min-h-screen-excluding-topbar">
        <View>
          <Sidebar />
          <div className="p-1">
            <Card className="pt-3 p-4 gap-4 items-start h-full payouts-grid bg-radial-gradient-dark dark:bg-radial-gradient-light">
              <Breadcrumbs
                items={[
                  { label: "All", href: "/revenue" },
                  {
                    label: `Epoch ${params.epoch}`,
                    href: `/revenue/${params.epoch}`,
                  },
                  {
                    label: params.date ? params.date.toString() : "Unknown",
                    href: `/revenue/${params.epoch}/${params.date}`,
                  },
                ]}
                onNavigate={(to: string) => navigate(to)}
              />
              <Toolbar className='!items-center !flex-row justify-between'>
                <Pagination
                  count={count}
                  limit={limit}
                  setLimit={setLimit}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
                {/*<button
                  onClick={hanldeExportCSV}
                  disabled={loading || Boolean(!payouts?.length)}
                  type="button"
                  className="rounded-md bg-blue-500 p-2 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Download csv
                </button>*/}
              </Toolbar>
              <List
                loading={loading}
                loadingMessage="Crunching data..."
                data={payouts}
                className="w-full h-full min-h-[40vh]"
                spanClass="text-white text-xs"
                onClick={(e: any, payout: PayoutResponse) => {
                  if (e.detail === 1) {
                    setSelectedWorker(payout.worker);
                  }
                  if (e.detail === 2) {
                    navigate(`/revenue/${params.epoch}/${payout.date}`);
                  }
                }}
                selected={(payout: PayoutResponse) => {
                  if (!selectedWorker) {
                    return false;
                  }
                  return payout.worker?.id === selectedWorker.id;
                }}
                schema={[
                  {
                    label: "Date",
                    key: (payout: PayoutResponse) => {
                      const formattedDate = payout.date
                        ? listUTCDateFormat(new UTCDate(payout.date))
                        : "Unknown date";
                      return (
                        <div className="flex items-center justify-start gap-1">
                          <span className="text-zinc-600 dark:text-white text-xs">
                            {formattedDate}
                          </span>
                        </div>
                      );
                    },
                    type: "custom",
                  },
                  {
                    label: "Worker",
                    key: (payout: PayoutResponse) => {
                      if (!payout.worker) {
                        return (
                          <span className="text-zinc-600 dark:text-white text-xs">
                            -
                          </span>
                        );
                      }
                      return (
                        <span className="text-zinc-600 dark:text-white text-xs">
                          {payout.worker.name}
                        </span>
                      );
                    },
                    type: "custom",
                  },
                  {
                    label: "Total Hashrate [TH/s]",
                    type: "custom",
                    key: (payout: PayoutResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.average_hashrate_thsd.toFixed(2)}
                      </span>
                    ),
                  },
                  {
                    label: "Passthrough [TH/s]",
                    type: "custom",
                    key: (payout: PayoutResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.seller_hashrate_thsd.toFixed(2)}
                      </span>
                    ),
                  },
                  {
                    label: "Rigly Premium [TH/s]",
                    type: "custom",
                    key: (payout: PayoutResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.order_hashrate_thsd.toFixed(2)}
                      </span>
                    ),
                  },
                  {
                    label: "FPPS Hashprice",
                    type: "sats",
                    key: (payout: PayoutResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {payout.hashprice_satsthsd.toFixed(1)}
                      </span>
                    ),
                  },
                  {
                    label: `Rigly Hashprice (+${team?.hash_price_premium}%)`,
                    type: "sats",
                    key: (payout: PayoutResponse) => (
                      <span className="text-zinc-600 dark:text-white text-xs font-mono">
                        {(payout.hashprice_satsthsd * ((100.0 + team?.hash_price_premium) / 100.0)).toFixed(1)}
                      </span>
                    ),
                  },
                  {
                    label: "Balance",
                    type: "custom",
                    key: (payout: PayoutResponse) => {
                      return (
                        <span className="text-zinc-600 dark:text-white text-xs">
                          {(payout.payout_amount_sats / 10 ** 8).toFixed(8)} BTC
                        </span>
                      );
                    },
                  },
                ]}
              />
              <div className="w-full h-full rounded-lg bg-zinc-100 dark:bg-zinc-800 border border-zinc-300 dark:border-zinc-700 flex flex-col">
                <div className="w-full p-2 border-b border-zinc-700 flex items-center justify-between">
                  <span className="text-xs text-zinc-600">
                    Showing -{" "}
                    <b>
                      {selectedWorker
                        ? `${selectedWorker.name} (${selectedWorker.id})`
                        : "All"}
                    </b>
                  </span>
                  {selectedWorker && (
                    <Button
                      className="w-auto px-2 h-[32px]"
                      onClick={() => setSelectedWorker(undefined)}
                    >
                      Show All
                    </Button>
                  )}
                  {!selectedWorker && <div className="h-[32px]" />}
                </div>
                <Plot
                  className="w-full h-full"
                  data={chartData?.data.map((dataset: any) => {
                    if (!selectedWorker || dataset.name === selectedWorker.name) {
                      return dataset;
                    }

                    return {
                      ...dataset,
                      x: [],
                      y: [],
                    };
                  })}
                  loading={loadingChart}
                  loadingMessage="Crunching data..."
                  xaxis={chartData?.xaxis}
                  yaxis={chartData?.yaxis}
                />
              </div>
            </Card>
          </div>
        </View>
      </div>
    </div>
  );
}
