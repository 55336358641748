import { useEffect, useState } from "react";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import StatisticCard from "../components/StatisticCard";
import View from "../components/View";
import MonthlyRevenue from "../components/MontlyRevenue";
import { useAuthContext } from "../context/AuthContext";
import { getTotalHashrate } from "../api/statistics";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../constants";
import toast from "react-hot-toast";
import { getProxies } from "../api/proxies";

type Statistic = {
  icon: string;
  title: string;
  description: string;
  value: any;
};


function Statistic() {
  const { team } = useAuthContext();

  const [isHashrateLoading, setIsHashrateLoading] = useState<boolean>(true);
  const [hashrate, setHashrate] = useState<number>(0);
  const [prevHashrate, setPrevHashrate] = useState<number>(0);

  const load = async () => {
    try {
      setIsHashrateLoading(true);
      const res = await getTotalHashrate(team.id);
      setHashrate(res.total_hashrate_ths);
      setPrevHashrate(res.prev_total_hashrate_ths);
    } catch (ex: any) {
      console.error("Error loading hashrate:", ex);
      toast.error(`Failed to fetch current hashrate: ${ex.message}`, { position: "bottom-right" });
    } finally {
      setIsHashrateLoading(false);
    }
  };

  useEffect(() => {
    if (team) {
      load();
      const interval = setInterval(load, 3600000); // Update every hour
      return () => clearInterval(interval);
    }
  }, [team]);

  return (
    <StatisticCard
      className="col-span-12 lg:col-span-6 xl:col-span-3"
      icon="gauge"
      title="Current Hashrate"
      description="Total hashrate across all proxies"
      value={hashrate.toFixed(2)}
      suffix="TH/s"
      loadingPlaceholder="..."
      loading={isHashrateLoading}
    />
  );
}

  export default function DashboardRoute() {
    return (
      <div className="flex flex-col">
        <Helmet>
          <title>{APP_NAME} - Dashboard</title>
        </Helmet>
        <Topbar />
        <div className="flex min-h-screen-excluding-topbar">
          <View>
            <Sidebar />
            <div className="p-3 w-full flex flex-col lg:h-full lg:grid lg:grid-cols-12 lg:grid-rows-[1fr,1fr,1fr,1fr,1fr] gap-4">
              <Statistic />
              <MonthlyRevenue className="col-span-12 row-span-2 xl:row-span-3" />
              {/* Add other components as needed */}
            </div>
          </View>
        </div>
      </div>
    );
  }
