import { UTCDate } from "@date-fns/utc";


export function listUTCDateFormat(date: UTCDate) {
    return (
      (date.getUTCMonth() + 1).toString().padStart(2, '0') + 
      '/' + 
      date.getUTCDate().toString().padStart(2, '0') + 
      '/' + 
      date.getUTCFullYear().toString()
    )
  }
  
  